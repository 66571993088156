<template>
  <div class="align-items-center">
    <button class="btn btn-secondary btn-sm" v-on:click="showModal()">
      <BaseIcon name="add" />
    </button>
  </div>
</template>
<script>
import BaseIcon from "@/components/icons/BaseIcon";

export default {
  name: "ManualProductRecommendation",
  components: { BaseIcon },
  props: {
    id: Number,
    variantId: Number,
    row: Object,
    modelValue: Array,
    extraRow: String,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("update", this.value, false);
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    showModal() {
      window.apps.callEvent(
        "manualProductRecommendations:show",
        this.variantId ?? this.id,
        this.modelValue
      );
    },
  },
  emits: ["update:modelValue", "update", "update:extraRow"],
};
</script>
